* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  background-color: black;
  display: flex;
  font-family: 'Geologica', sans-serif;
}

button {
  border: none;
  padding: .2rem;
  font-family: 'Geologica', sans-serif;
  font-weight: 100;
  background-color: white;
  color: black;
  text-decoration: none;
}

button:hover {
  cursor: pointer;
}

button:disabled{
  cursor: not-allowed;
  background-color: #777;
}

@media only screen and (min-width: 600px)  {
  button {
    font-weight: 200;
  }
}

.sort-speed-title {
  margin-bottom: 1rem;
  text-align: center;
}

#visualizer {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all ease .3s;
}

#visualizer > .button-bar {
  color: white;
  display: flex;
  flex-direction: column;
}

.visualizer-container {
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin: 0 auto;
  padding: 5% 0;
  gap: 1px;
}

.generate-bars-container {
  margin-top: .5rem;
}

.generate-bars-container > button {
  width: 100%;
}

.sort-buttons-container {
  margin-top: .2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1px;
}

.sort-buttons-container > button {
  flex: 1 1 0;
  height: 3rem;
}

@media only screen and (min-width: 600px)  {
  .sort-buttons-container {
    display: flex;
    gap: 1px;
  }

  .sort-buttons-container > button {
    flex: 1 1 0;
    height: 2rem;
  }
}

.options-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

.dark-mode-container {
  display: flex;
  align-items: center;
}

.sort-speed-container {
  width: 60%;
}

@media only screen and (min-width: 600px)  {
  .sort-speed-container {
    width: 25%;
  }
}

output.speed-description {
  padding-top: 5px;
  font-weight: 100;
  width: 100%;
  text-align: right;
  position: absolute;
  left: -120px;
}

@media only screen and (min-width: 600px)  {
  output.speed-description {
    font-size: smaller;
  }
}

input#sort-speed {
  padding-left: 5px;
}

@media only screen and (min-width: 600px)  {
  input#sort-speed {
    padding-left: 10px;
  }
}

/* Dark Mode toggle from: https://redstapler.co/pure-css-minimal-dark-mode-toggle-button/ */
label.dark-mode-label {
  width: 40px;
  height: 20px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0,0,0,0.4), inset 0px -5px 15px rgba(255,255,255,0.4);
  cursor: pointer;
}

label.dark-mode-label:after {
  content: "";
  width:18px;
  height: 18px;
  position: absolute;
  top:1px;
  left:1px;
  background: linear-gradient(180deg,#ffcc89,#d8860b);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
}

input.dark-mode-toggle {
  width: 0;
  height: 0;
  visibility: hidden;
}

input.dark-mode-toggle:checked + label.dark-mode-label {
  background: #242424;
}

input.dark-mode-toggle:checked + label.dark-mode-label:after {
  left:39px;
  transform: translateX(-100%);
  background: linear-gradient(180deg,#777,#3a3a3a);
}

label.dark-mode-label, label.dark-mode-label:after {
  transition: 0.3s;
}
/* ******************************************************************************** */

@keyframes append-animate {
	from {
		transform: scaleY(0);
		opacity: 0;
	}
	to {
		transform: scaleY(1);
		opacity: 1;
	}
}

.bar {
  transition: all ease .3s;
  flex: 1;
  display: flex;
  min-width: 2px;
  align-items: end;
  font-size: x-small;
  background-color: white;
  animation: append-animate .5s linear;
}

.bar.selected {
  background-color: green;
}

#visualizer.light-mode {
  background-color: azure;
}

#visualizer.light-mode .bar {
  background-color: black;
}

#visualizer.light-mode > .button-bar {
  color: black
}

#visualizer.light-mode button {
  color: white;
  background-color: black;
}

#visualizer.light-mode button:disabled{
  cursor: not-allowed;
  background-color: #777;
}

.bar {
  transition: all ease .3s;
  flex: 1;
  display: flex;
  min-width: 2px;
  align-items: end;
  font-size: x-small;
  background-color: white;
  color: red;
}

.bar.selected {
  background-color: green;
}

.bar.sorted {
  background-color: red;
}

#visualizer.light-mode .bar.selected {
  background-color: blue;
}

#visualizer.light-mode .bar.sorted {
  background-color: rgb(93, 162, 162);
}

.bar.swapped, #visualizer.light-mode .bar.swapped {
  background-color: #f64fca;
}

/* Slider styles from https://www.smashingmagazine.com/2021/12/create-custom-range-input-consistent-browsers/ */

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
   appearance: none;
   background: transparent;
   cursor: pointer;
   width: 15rem;
}

/* Removes default focus */
input[type="range"]:focus {
 outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: white;
  border-radius: 0.5rem;
  height: 0.1rem;  
}

#visualizer.light-mode input[type="range"]::-webkit-slider-runnable-track {
  background-color: black;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
 -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -15px; /* Centers thumb on the track */
  border-radius: 5px;
  box-shadow: inset 0px 5px 15px rgba(0,0,0,0.4), inset 0px -5px 15px rgba(255,255,255,0.4);

  /*custom styles*/
  background-color: black;
  height: 2rem;
  width: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  border: 1px solid rgba(255,255,255,0.4);
  outline-offset: 0.125rem; 
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: white;
  border-radius: 0.5rem;
  height: 0.1rem;
}

#visualizer.light-mode input[type="range"]::-moz-range-track {
  background-color: black;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 5px;
  box-shadow: inset 0px 5px 15px rgba(0,0,0,0.4), inset 0px -5px 15px rgba(255,255,255,0.4);

  /*custom styles*/
  background-color: black;
  height: 2rem;
  width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
 border: 1px solid rgba(255,255,255,0.4);
 outline-offset: 0.125rem; 
}

