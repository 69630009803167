* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #000;
  min-width: 100vw;
  min-height: 100vh;
  font-family: Geologica, sans-serif;
  display: flex;
}

button {
  color: #000;
  background-color: #fff;
  border: none;
  padding: .2rem;
  font-family: Geologica, sans-serif;
  font-weight: 100;
  text-decoration: none;
}

button:hover {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  background-color: #777;
}

@media only screen and (width >= 600px) {
  button {
    font-weight: 200;
  }
}

.sort-speed-title {
  text-align: center;
  margin-bottom: 1rem;
}

#visualizer {
  flex-direction: column;
  width: 100%;
  transition: all .3s;
  display: flex;
}

#visualizer > .button-bar {
  color: #fff;
  flex-direction: column;
  display: flex;
}

.visualizer-container {
  flex: 1;
  align-items: flex-end;
  gap: 1px;
  margin: 0 auto;
  padding: 5% 0;
  display: flex;
}

.generate-bars-container {
  margin-top: .5rem;
}

.generate-bars-container > button {
  width: 100%;
}

.sort-buttons-container {
  grid-gap: 1px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  margin-top: .2rem;
  display: grid;
}

.sort-buttons-container > button {
  flex: 1 1 0;
  height: 3rem;
}

@media only screen and (width >= 600px) {
  .sort-buttons-container {
    gap: 1px;
    display: flex;
  }

  .sort-buttons-container > button {
    flex: 1 1 0;
    height: 2rem;
  }
}

.options-container {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
}

.dark-mode-container {
  align-items: center;
  display: flex;
}

.sort-speed-container {
  width: 60%;
}

@media only screen and (width >= 600px) {
  .sort-speed-container {
    width: 25%;
  }
}

output.speed-description {
  text-align: right;
  width: 100%;
  padding-top: 5px;
  font-weight: 100;
  position: absolute;
  left: -120px;
}

@media only screen and (width >= 600px) {
  output.speed-description {
    font-size: smaller;
  }
}

input#sort-speed {
  padding-left: 5px;
}

@media only screen and (width >= 600px) {
  input#sort-speed {
    padding-left: 10px;
  }
}

label.dark-mode-label {
  cursor: pointer;
  background: #ebebeb;
  border-radius: 200px;
  width: 40px;
  height: 20px;
  display: block;
  position: relative;
  box-shadow: inset 0 5px 15px #0006, inset 0 -5px 15px #fff6;
}

label.dark-mode-label:after {
  content: "";
  background: linear-gradient(#ffcc89, #d8860b);
  border-radius: 180px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 1px;
  box-shadow: 0 5px 10px #0003;
}

input.dark-mode-toggle {
  visibility: hidden;
  width: 0;
  height: 0;
}

input.dark-mode-toggle:checked + label.dark-mode-label {
  background: #242424;
}

input.dark-mode-toggle:checked + label.dark-mode-label:after {
  background: linear-gradient(#777, #3a3a3a);
  left: 39px;
  transform: translateX(-100%);
}

label.dark-mode-label, label.dark-mode-label:after {
  transition: all .3s;
}

@keyframes append-animate {
  from {
    opacity: 0;
    transform: scaleY(0);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

.bar {
  background-color: #fff;
  flex: 1;
  align-items: end;
  min-width: 2px;
  font-size: x-small;
  transition: all .3s;
  animation: .5s linear append-animate;
  display: flex;
}

#visualizer.light-mode {
  background-color: azure;
}

#visualizer.light-mode .bar {
  background-color: #000;
}

#visualizer.light-mode > .button-bar {
  color: #000;
}

#visualizer.light-mode button {
  color: #fff;
  background-color: #000;
}

#visualizer.light-mode button:disabled {
  cursor: not-allowed;
  background-color: #777;
}

.bar {
  color: red;
  background-color: #fff;
  flex: 1;
  align-items: end;
  min-width: 2px;
  font-size: x-small;
  transition: all .3s;
  display: flex;
}

.bar.selected {
  background-color: green;
}

.bar.sorted {
  background-color: red;
}

#visualizer.light-mode .bar.selected {
  background-color: #00f;
}

#visualizer.light-mode .bar.sorted {
  background-color: #5da2a2;
}

.bar.swapped, #visualizer.light-mode .bar.swapped {
  background-color: #f64fca;
}

input[type="range"] {
  appearance: none;
  cursor: pointer;
  background: none;
  width: 15rem;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: #fff;
  border-radius: .5rem;
  height: .1rem;
}

#visualizer.light-mode input[type="range"]::-webkit-slider-runnable-track {
  background-color: #000;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background-color: #000;
  border-radius: 5px;
  width: 1rem;
  height: 2rem;
  margin-top: -15px;
  box-shadow: inset 0 5px 15px #0006, inset 0 -5px 15px #fff6;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: .125rem;
  border: 1px solid #fff6;
}

input[type="range"]::-moz-range-track {
  background-color: #fff;
  border-radius: .5rem;
  height: .1rem;
}

#visualizer.light-mode input[type="range"]::-moz-range-track {
  background-color: #000;
}

input[type="range"]::-moz-range-thumb {
  background-color: #000;
  border: none;
  border-radius: 5px;
  width: 1rem;
  height: 2rem;
  box-shadow: inset 0 5px 15px #0006, inset 0 -5px 15px #fff6;
}

input[type="range"]:focus::-moz-range-thumb {
  outline-offset: .125rem;
  border: 1px solid #fff6;
}

/*# sourceMappingURL=index.15d5c4cf.css.map */
